<template>
  <div>
    我是wap首页的区域
  </div>
</template>

<script>
export default {
  name: 'Backhome',
  data() {
    return {
      msg: '后台管理',

      ueditorVal: ''
    }
  },
  methods: {

    ueditorMsg(val) {

    }
  }
}
</script>

<style scoped lang='scss'>
.backhome{
    position: relative;
    // background: url("../../assets/img/index-img.jpg");
    height: 100vh;
    background-size: cover;
    .welcome{
      padding: 100px 0 0 80px;
      font-size: 18px;
      color:#414141;
    }
    .titleH{
      position: absolute;
      left: 100px;
      bottom: 140px;
      font-size: 46px;
      font-weight: bold;
      color: #0033a4;
      letter-spacing: 10px;

    }
    .copy{
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      line-height: 78px;
      text-align: center;
      font-size: 12px;
      color: #9ea6bb;
    }
    .line{
      height: 1px;
      background: #8793b7;
      opacity: .1;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 78px;
    }
}
</style>
